import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'
import { WorkPricesCoefficientsModel } from './WorkPricesCoefficientsModel'

export const WorkPriceModel = types.model('WorkPriceModel', {
  id: types.number,
  currencyId: types.number,
  workId: types.number,
  isDeletable: types.boolean,
  lastModified: types.string,
  price: types.number,
  work: DictionaryModel,
  currency: DictionaryModel,
  coefficients: types.array(WorkPricesCoefficientsModel),
})
