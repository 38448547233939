import { types } from 'mobx-state-tree'
// import { CurrencyFormModel } from '../common'

export const CurrencySelectModel = types.model('CurrencySelectModel', {
  id: types.number,
  name: types.string,
  isoCode: types.string,
  countryId: types.maybeNull(types.number),
  lastModified: types.string,
  isDeletable: types.boolean,
})
