import { types } from 'mobx-state-tree'
import { CurrencyFormModel } from '../common'

export const CurrencyModel = types.model('CurrencyModel', {
  id: types.number,
  ratioToUsd: types.number,
  currency: CurrencyFormModel,
  // currencyFrom: CurrencyFormModel,
  // currencyTo: CurrencyFormModel,
  // id: types.number,
  // name: types.string,
  // ratio: types.number,
  // isoCode: types.string,
  // countryId: types.maybeNull(types.number),
  // lastModified: types.string,
  // isDeletable: types.boolean,
})
