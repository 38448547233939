import { types } from 'mobx-state-tree'
import { WorkPriceModel } from './WorkPriceModel'
import { DictionaryModel } from '../common'

export const WorkCoefficientModel = types.model('MaterialCoefficientModel', {
  id: types.number,
  priceRegionId: types.number,
  workPriceId: types.number,
  totalPrice: types.number,
  coefficient: types.number,
  lastModified: types.string,
  priceRegion: DictionaryModel,
  workPrice: WorkPriceModel,
})
