import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'
import { MaterialPricesCoefficientsModel } from './MaterialPricesCoefficientsModel'

export const MaterialPriceModel = types.model('MaterialPriceModel', {
  id: types.number,
  currencyId: types.number,
  materialId: types.number,
  isDeletable: types.boolean,
  lastModified: types.string,
  price: types.number,
  material: DictionaryModel,
  currency: DictionaryModel,
  coefficients: types.array(MaterialPricesCoefficientsModel),
  measurementUnit: types.maybeNull(DictionaryModel),
  // countryId: types.number,
  // discountPercent: types.number,
})
