import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'

export const MaterialPricesCoefficientsModel = types.model('MaterialPricesCoefficientsModel', {
  id: types.number,
  priceRegionId: types.number,
  materialPriceId: types.number,
  totalPrice: types.number,
  coefficient: types.number,
  lastModified: types.string,
  priceRegion: DictionaryModel,
})
