import { types, applySnapshot, getSnapshot } from 'mobx-state-tree'
import { CountryModel } from 'stores/models'

const AppStore = types
  .model('AppStore')
  .props({
    _currentLanguage: types.optional(types.union(types.literal('ru'), types.literal('en'), types.literal('kg'), types.literal('tj')), 'ru'),
    _currentCountry: types.optional(CountryModel, {}),
  })
  .actions(self => {
    const setLanguage = language => {
      self._currentLanguage = language
    }

    const setCountry = country => {
      applySnapshot(self._currentCountry, country)
    }

    return {
      setLanguage,
      setCountry,
    }
  })
  .views(self => ({
    get currentLanguage() {
      return self._currentLanguage
    },

    get currentCountry() {
      return getSnapshot(self._currentCountry)
    },
  }))

export default AppStore
