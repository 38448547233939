import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'

export const WorkPricesCoefficientsModel = types.model('WorkPricesCoefficientsModel', {
  id: types.number,
  priceRegionId: types.number,
  workPriceId: types.number,
  totalPrice: types.number,
  coefficient: types.number,
  lastModified: types.string,
  priceRegion: DictionaryModel,
})
