import { types } from 'mobx-state-tree'
import { MaterialPriceModel } from './MaterialPriceModel'
import { DictionaryModel } from '../common'

export const MaterialCoefficientModel = types.model('MaterialCoefficientModel', {
  id: types.number,
  priceRegionId: types.number,
  materialPriceId: types.number,
  totalPrice: types.number,
  coefficient: types.number,
  lastModified: types.string,
  priceRegion: DictionaryModel,
  materialPrice: MaterialPriceModel,
})
