import { types } from 'mobx-state-tree'

export const DictionaryModel = types.model('DictionaryModel', {
  id: types.number,
  name: types.string,
})

export const CurrencyFormModel = types.model('CurrencyFormModel', {
  id: types.number,
  name: types.string,
  isoCode: types.string,
  countryId: types.maybeNull(types.number),
})

export const TranslationsModel = types.model('TranslationsModel', {
  priceRegionName: types.string,
  cultureName: types.string,
})
