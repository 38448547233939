import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'
import { SupplierModel } from './SupplierModel'
import { SupplierPhysicalRegionsModel } from './SupplierPhysicalRegionsModel'

export const SupplierRegionsModel = types.model('SupplierRegionsModel', {
  id: types.number,
  name: types.string,
  isDefault: types.boolean,
  isMain: types.boolean,
  countryId: types.number,
  priceRegionId: types.maybeNull(types.number),
  priceRegion: types.maybeNull(DictionaryModel),
  updatedUtc: types.maybeNull(types.string),
  createdUtc: types.maybeNull(types.string),
  suppliers: types.maybeNull(types.array(SupplierModel)),
  // physicalRegions: types.maybeNull(types.array(SupplierPhysicalRegionsModel)),
})
